@use '@angular/material' as mat;
@include mat.core();

html,
body {
  height: 100%;
  background-color: #f0f2f8;
}

body {
  margin: 0;
  font-family: 'helvetica', arial, 'hiragino kaku gothic pro', meiryo,
    'ms pgothic', sans-serif;
}

app-root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  touch-action: manipulation;
}

h2 {
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 2px solid #66c;
}

h2 span {
  font-size: 14px;
}

h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #19233c;
}

h3 span {
  font-size: 12px;
}

.container {
  width: 100%;
  max-width: 1400px;
  flex: 1;
}

.map-icon-label {
  text-align: center;
  color: #000000;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #ffffff, -1px 1px 1px #ffffff, 1px -1px 1px #ffffff,
    -1px -1px 1px #ffffff, 1px 0px 1px #ffffff, 0px 1px 1px #ffffff,
    -1px 0px 1px #ffffff, 0px -1px 1px #ffffff;
}

.flexbox-container {
  display: flex;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  min-height: 740px;
}

.feature-item {
  flex: 0 0 49%;
  max-width: 49%;
  margin-right: 1rem;
}

.feature-item:last-of-type {
  margin-right: 0;
}

.feature-item4 {
  flex: 0 0 30%;
  max-width: 30%;
  margin: auto;
}

/* Heatmap option */
#xymapbox select::-ms-expand {
  display: none;
}

#xymapbox select {
  margin-right: 40px;
  padding: 6px 10px;
  width: 150px;
  font-size: 12px;
  font-weight: 400;
  background-color: #f8f9fc;
  border-radius: 3px;
  border: 1px solid #66c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/assets/select-arrow.svg');
  background-repeat: no-repeat;
  background-size: 10px 8px;
  background-position: right 10px center;
}

app-root .leaflet-top,
.leaflet-bottom {
  z-index: 800;
}

@media screen and (min-width: 1024px) {
  .app-root {
    min-width: 1400px;
  }
}

/* mobile */
@media screen and (max-width: 1024px) {
  .container {
    margin: 0;
  }
}

.fa-minus {
  color: #999;
}
